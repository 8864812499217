<template>
  <div class="upw-filter">
    <div class="upw-filter__div" @click="toggleFilter">
      <img
        src="@/assets/images/achievements/list/filter.svg"
        alt="filter"
        class="upw-filter__content"
      />
      <span class="upw-filter__content">{{ filterCount }}</span>
      <img
        :src="
          isFilterOpen
            ? require('@/assets/images/achievements/list/chevron-up.svg')
            : require('@/assets/images/achievements/list/chevron-down.svg')
        "
        alt="down"
        class="upw-filter__content"
      />
    </div>
    <FilterMenu v-show="isFilterOpen" :filterMenu.sync="filterMenu" />
  </div>
</template>

<script>
import filterMenu from "@/core/mixins/filterMenu";
import FilterMenu from "./FilterMenu.vue";
export default {
  props: {
    menu: {
      type: Array,
      required: true
    }
  },
  mixins: [filterMenu],
  components: {
    FilterMenu
  },
  data() {
    return {
      isFilterOpen: false,
      filterMenu: []
    };
  },
  computed: {
    filterCount() {
      let count = 0;
      this.filterMenu.forEach(fm => {
        if (Array.isArray(fm.selected)) {
          count += fm.selected.length;
        } else if (fm.selected !== "") {
          count += 1;
        }
      });
      return count;
    }
  },
  watch: {
    filterMenu: {
      handler() {
        this.$emit("filterChange", this.filterMenu);
      },
      deep: true
    }
  },
  created() {
    this.filterMenu = this.menu;
  },
  methods: {
    toggleFilter() {
      this.isFilterOpen = !this.isFilterOpen;
    }
  }
};
</script>
<style lang="scss" scoped>
.upw-filter__div {
  background: $brand-neutral-50;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
}
</style>
